import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';

import config from '../../config';

const SITE_URL = 'https://togather.com';

const Meta = ({
  title,
  subtitle,
  description,
  image,
  path,
  type,
  averageRating,
  schemaType,
  reviewCount,
  blockPage,
}) => {
  const url = `${SITE_URL}${path}`;
  const formattedTitle = subtitle ? `${subtitle} | ${title}` : title;

  const reviewSchema =
    !!averageRating &&
    !!reviewCount &&
    `
    {
      "@context": "http://schema.org",
      "@type": "${schemaType}",
      "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": "${averageRating}",
        "reviewCount": "${reviewCount}"
      },
      "name": "${subtitle}",
      "description": "${description}",
      "url": "${url}",
      "image": [
        "${image}"
      ]
      ${
        schemaType === 'Product'
          ? `,
        "brand": {
          "@type": "Service",
          "name": "Togather"
        }
      `
          : ''
      }
    }
  `;

  const [shouldBlockCrawlers, setShouldBlockCrawlers] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const { hostname } = window.location;
      if (hostname === 'tahini.feast-it.com') {
        setShouldBlockCrawlers(true);
      }
    }
  }, []);

  return (
    <Head>
      <title>{formattedTitle}</title>
      {(config.blockCrawlers || blockPage || shouldBlockCrawlers) && (
        <meta name="robots" content="noindex, nofollow" />
      )}
      <meta name="description" content={description} />
      <meta property="og:title" content={formattedTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:type" content={type} />
      <meta property="og:url" content={url} />
      <meta
        name="facebook-domain-verification"
        content="rhl9vedef2uwjuhubv5kcxzmddp1kf"
      />
      <link rel="canonical" href={url} />
      <link rel="alternate" hrefLang="en-gb" href={url} />

      {reviewSchema && (
        <script type="application/ld+json">{reviewSchema}</script>
      )}
    </Head>
  );
};

Meta.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  averageRating: PropTypes.number,
  reviewCount: PropTypes.number,
  schemaType: PropTypes.oneOf(['Product', 'LocalBusiness']).isRequired,
  blockPage: PropTypes.bool,
};

Meta.defaultProps = {
  title: 'Togather',
  path: '',
  subtitle: 'Book Unique Street Food Caterers',
  description:
    'Discover, compare and easily book high quality street food caterers for your event.',
  image: `${SITE_URL}/images/meta/open-graph.jpg`,
  type: 'website',
  schemaType: 'Product',
  blockPage: false,
};

export default Meta;
