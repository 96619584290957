import Prismic from 'prismic-javascript';
import { RichText } from 'prismic-reactjs';
import axios from 'axios';

import config from '../../config';
import { SCHEMA_TYPES } from '../../constants/prismic';
import { toSentenceCase } from '../formatting';
import { Client as ContentfulClient } from '../contentful';
import { initializeApollo } from '../apollo';
import { GET_TREATMENT_FOR_IDENTIFIER } from '../../hooks/testTreatment/getTestTreatmentForIdentifier';

let frontClient;

export const Client = (req = null) => {
  // prevents generating a new instance for client side since we don't need the refreshed request object.
  if (!req && frontClient) {
    return frontClient;
  } else {
    const options = Object.assign(
      {},
      req ? { req } : {},
      config.prismicKey ? { accessToken: config.prismicKey } : {}
    );
    return Prismic.client(config.prismicUri, options);
  }
};

export const linkResolver = doc => {
  const path = doc.uid.replace(/\./g, '/');
  return `/${path}`;
};

export const hrefResolver = doc => {
  const path = doc.uid.split('.')[0];
  return `/${path}/[id]`;
};

export const formatFeaturedPages = pages =>
  pages.map(page => ({
    href: hrefResolver(page),
    as: linkResolver(page),
    text: RichText.asText(page.data.title),
  }));

export const formatMetadata = async (data, keyword) => {
  const metadata = {
    subtitle: keyword
      ? toSentenceCase(keyword)
      : RichText.asText(data.meta_title),
    description: RichText.asText(data.meta_description),
    image: data?.meta_image?.url || '',
  };

  const hasReviewSchema = data.schema === SCHEMA_TYPES.companyReviews;

  if (hasReviewSchema) {
    const { data } = await axios.get(
      'https://api.reviews.co.uk/merchant/reviews?store=feast-it-com'
    );

    metadata.averageRating = Number(data.stats.average_rating);
    metadata.reviewCount = Number(data.stats.total_reviews);
  }

  return metadata;
};

export const hasBorderlessHeader = slices => {
  const borderlessHeaderSlices = ['blob_hero'];

  return !!slices.find(({ slice_type }) => {
    return borderlessHeaderSlices.includes(slice_type);
  });
};

export const hasTransparentHeader = (slices, isDesktop) => {
  const transparentHeaderSlices = ['hero_with_postcode', 'landing_page_hero'];
  const mobileOnlyTransparentHeaderSlices = ['blob_hero'];

  return !!slices.find(({ slice_type }) => {
    if (!isDesktop) {
      return mobileOnlyTransparentHeaderSlices.includes(slice_type);
    }

    return transparentHeaderSlices.includes(slice_type);
  });
};

export const getLatestBlog = async slices => {
  const blogSlice = slices.find(({ slice_type }) => slice_type === 'blog');

  if (!blogSlice) return;

  const articleSlug = blogSlice?.primary?.contentful_article_slug;
  let data;

  if (articleSlug) {
    data = await ContentfulClient.getEntries({
      content_type: 'blogPost',
      'fields.slug': articleSlug,
    });
  } else {
    data = await ContentfulClient.getEntries({
      content_type: 'blogPost',
      limit: 1,
    });
  }

  return data?.items?.[0];
};

export async function getPrismicPageVariant(context, user, pageId, testName) {
  const apolloClient = initializeApollo(null, context);
  const treatmentResponse = await apolloClient.query({
    query: GET_TREATMENT_FOR_IDENTIFIER,
    variables: {
      identifier: user,
      test: testName,
    },
  });

  const treatmentName = treatmentResponse.data?.getTreatment?.treatment;

  const contentIdLookup = {
    off: pageId,
    A: `${pageId}-treatment-a`,
    B: `${pageId}-treatment-b`,
  };

  const contentId = contentIdLookup[treatmentName] ?? pageId;

  return { contentId, treatmentName };
}
