import compose from 'just-compose';

import config from '../config';
import { captureError } from './monitoring';

export const Client = require('contentful').createClient({
  space: config.contentfulSpace,
  accessToken: config.contentfulKey,
});

const BLOG_FIELDS = [
  'title',
  'publishedDate',
  'slug',
  'url',
  'body',
  'teaserBody',
  'images',
  'tags',
];

// Common query options when fetching stuff from the Contentful API
const QUERY_OPTIONS = {
  content_type: 'blogPost',
};

// Pagination
const withLimit = (page = 0, limit = 9) => query => ({
  ...query,
  limit,
  skip: page * limit,
});

// Sorting
const withOrder = (sort = 'publishedDate:desc') => query => {
  const [field, direction = 'asc'] = sort.split(':');

  const reverse = direction === 'desc' ? '-' : '';

  return {
    ...query,
    order: `${reverse}fields.${field}`,
  };
};

// It's unclear from the Contentful code written for the API, although
// my own testing seems to indicate that fields are of type string
const getField = field => (field && field['en-GB']) || field || '';

// Maps image entry properties
const processImages = imagesRaw => blogEntry => ({
  ...blogEntry,
  images: (imagesRaw || [])
    .filter(image => image && image.fields && image.fields.file)
    .map(({ sys: { id }, fields: { title, file: { url } } }) => ({
      id,
      eid: blogEntry.id,
      url: getField(url).replace(/^\/\//, ''),
      title: getField(title),
    })),
});

// Maps tag entry properties
const processTags = tagsRaw => blogEntry => ({
  ...blogEntry,
  tags: (tagsRaw || [])
    .filter(tag => tag && tag.fields && tag.fields.id && tag.fields.name)
    .map(({ fields: { id, name } }) => ({
      id: getField(id),
      name: getField(name),
    })),
});

// Creates created date
const withCreatedDate = ({ publishedDate, ...rest }) => ({
  created: publishedDate || new Date(),
  ...rest,
});

// Maps a Contentful API response entry to an object ready for display
function processBlogEntry(entry) {
  if (!entry) {
    throw new Error('Blog entry not found');
  }

  const fields = BLOG_FIELDS.reduce(
    (last, field) => ({
      ...last,
      [field]: getField(entry.fields[field]),
    }),
    {
      id: entry.sys.id,
      teaserBody: '',
      updatedAt: entry.sys.updatedAt,
    }
  );

  return compose(
    processImages(fields.images),
    processTags(fields.tags),
    withCreatedDate
  )(fields);
}

// Retrieves paginated entries matching a particular category
export async function getByCategory(category, page, limit, sort) {
  const tag = await Client.getEntries({
    content_type: 'tags',
    limit: 1,
    'fields.id[match]': category,
  });

  if (!tag.total) {
    return {};
  }

  const { items, total } = await Client.getEntries(
    compose(
      withLimit(page, limit),
      withOrder(sort)
    )({
      ...QUERY_OPTIONS,
      'fields.tags.sys.id': tag.items[0].sys.id,
    })
  );

  const results = await Promise.all(items.map(processBlogEntry));

  return {
    results,
    total,
  };
}

// Retrieves a single entry by its slug
export async function getBySlug(slug) {
  const entries = await Client.getEntries({
    ...QUERY_OPTIONS,
    'fields.slug': slug,
  });

  return {
    results: [processBlogEntry(entries.items[0])],
    total: 1,
  };
}

// Retrieves all entries (paginated)
export async function getAll(page, limit, sort) {
  try {
    const { items, total } = await Client.getEntries(
      compose(
        withLimit(page, limit),
        withOrder(sort)
      )(QUERY_OPTIONS)
    );

    const results = await Promise.all(items.map(processBlogEntry));

    return {
      results,
      total,
    };
  } catch (error) {
    captureError(error);
    return { error };
  }
}

export default Client;
