import { gql } from '@apollo/client';

export const GET_TREATMENT_FOR_IDENTIFIER = gql`
  query getTreatment($identifier: String!, $test: String!) {
    getTreatment(identifier: $identifier, test: $test) {
      name
      treatment
    }
  }
`;
