import React from 'react';
import dynamic from 'next/dynamic';
import { ErrorBoundary } from 'react-error-boundary';

import { captureError } from '../../modules/monitoring';

const LandingPageHero = dynamic(() => import('./LandingPageHero'));
const Accordion = dynamic(() => import('./Accordion'));
const BlogLatest = dynamic(() => import('./BlogLatest'));
const BlogSection = dynamic(() => import('./BlogSection'));
const Caption = dynamic(() => import('./Caption'));
const Category = dynamic(() => import('./Category'));
const ContactUs = dynamic(() => import('./ContactUs'));
const FullWidthImageHeading = dynamic(() => import('./FullWidthImageHeading'));
const HeroWithPostcode = dynamic(() => import('./HeroWithPostcode'));
const ImageAndCopy = dynamic(() => import('./ImageAndCopy'));
const VideoAndCopy = dynamic(() => import('./VideoAndCopy'));
const ImageCarousel = dynamic(() => import('./ImageCarousel/ImageCarousel'));
const TestimonialsEditable = dynamic(() => import('./TestimonialsEditable'));
const ImageCTAs = dynamic(() => import('./ImageCTAs'));
const InterestedInApplying = dynamic(() => import('./InterestedInApplying'));
const Logos = dynamic(() => import('./Logos'));
const Quote = dynamic(() => import('./Quote'));
const StickyCTA = dynamic(() => import('./StickyCTA'));
const Recommendations = dynamic(() => import('./Recommendations'));
const RecommendationsEditable = dynamic(() =>
  import('./RecommendationsEditable')
);
const RecommendationsPress = dynamic(() => import('./RecommendationsPress'));
const Stats = dynamic(() => import('./Stats'));
const SupplierGrid = dynamic(() => import('./SupplierGrid'));
const SupplyTestimonial = dynamic(() => import('./SupplyTestimonial'));
const SupplyHero = dynamic(() => import('./SupplyHero'));
const WhyChooseFeastIt = dynamic(() => import('./WhyChooseFeastIt'));
const WhyFeastIt = dynamic(() => import('./WhyFeastIt'));
const WhyWeAreDifferent = dynamic(() => import('./WhyWeAreDifferent'));
const PartnerGrid = dynamic(() => import('./PartnerGrid'));
const BlobHero = dynamic(() => import('./BlobHero'));
const BlobImageAndText = dynamic(() => import('./BlobImageAndText'));
const GridView = dynamic(() => import('./GridView'));
const ImageGallery = dynamic(() => import('./ImageGallery'));
const ThreeColumns = dynamic(() => import('./ThreeColumns'));

const types = {
  '3_image_ctas': ImageCTAs,
  accordion: Accordion,
  blog: BlogLatest,
  blog_section: BlogSection,
  caption: Caption,
  category: Category,
  contact_us: ContactUs,
  editable_recommendations: RecommendationsEditable,
  full_width_image___heading: FullWidthImageHeading,
  hero_with_postcode: HeroWithPostcode,
  image_and_copy: ImageAndCopy,
  video_and_copy: VideoAndCopy,
  image_carousel: ImageCarousel,
  interested_in_applying: InterestedInApplying,
  landing_page_hero: LandingPageHero,
  logos: Logos,
  partner_grid: PartnerGrid,
  quote: Quote,
  recommendations: Recommendations,
  stats: Stats,
  supplier_grid: SupplierGrid,
  supply_onboarding_testimonial: SupplyTestimonial,
  supply_hero: SupplyHero,
  sticky_cta: StickyCTA,
  testimonials_editable: TestimonialsEditable,
  press_recommendations: RecommendationsPress,
  why_choose_feast_it: WhyChooseFeastIt,
  why_feast_it_section: WhyFeastIt,
  why_we_re_different_section: WhyWeAreDifferent,
  blob_hero: BlobHero,
  blob_image_and_copy: BlobImageAndText,
  portrait_carousel: GridView,
  image_gallery: ImageGallery,
  three_column: ThreeColumns,
};

export default function(sections, keyword) {
  return sections.map((section, i) => {
    const Component = types[section.slice_type];

    if (!Component) {
      // For development purposes
      // eslint-disable-next-line no-console
      console.warn('Missing Prismic component: ', section.slice_type);

      return null;
    }

    return (
      <ErrorBoundary
        key={`prismic-section-${i}`}
        FallbackComponent={() => null}
        onError={error => captureError(error)}
      >
        <Component keyword={keyword} {...section} />
      </ErrorBoundary>
    );
  });
}
